<template>
  <div class="Purchase-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Purchase" :title="`Purchase By Date`" :start="start" :end="end" />

        <v-container fluid>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table dense sort-by="date" readonly :headers="headers" :itemsPerPage="Purchase.length"
                  :sort-by="sortBy" hide-default-footer :items="Purchase">
                  <template v-slot:item="{ item }">
                    <tr @click="viewPurchase(item)" class="ponter">
                      <td class="text-left">
                        {{ item.date | moment("MM/DD/YYYY") }}
                      </td>
                      <td class="text-left">
                        {{ item.localId }}
                      </td>
                      <td class="text-left text-capitalize" v-if="item.Vendor">
                        {{ item.Vendor.name }}
                      </td>
                      <td class="text-left text-capitalize" v-else>Null</td>
                      <td class="text-left text-capitalize">
                        <v-chip v-if="item.due === 0" small color="red" label text-color="white">Paid</v-chip>
                      </td>
                      <td class="text-right">
                        {{ item.total | currency }}
                      </td>
                      <td class="text-right">
                        {{ item.due | currency }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:body.append="{ headers }" v-if="Purchase.length > 0">
                    <tr :style="{ 'background-color': $style.listTotal.filter }">
                      <td></td>
                      <td :colspan="headers.length - 3" class="text-right font-weight-bold">
                        Total
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ total | currency }}
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ due | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
// import CategoryService from "@/modules/Category/service";
import PurchaseService from "@/modules/Inventory/Purchase/service";
// import Hashids from "hashids";
// const hashids = new Hashids();

export default {
  data() {
    return {
      start: null,
      end: null,
      isLoading: true,
      Purchase: [],
      sortBy: "date",
      headers: [
        {
          text: "DATE",
          align: "left",
          value: "date",
          sortable: true,
        },
        {
          text: "ID",
          align: "left",
          value: "localId",
          sortable: true,
        },

        {
          text: "Vendor",
          align: "left",
          value: "vendor.name",
          sortable: true,
        },
        {
          text: "",
          align: "left",
          value: "status",
          sortable: true,
        },
        {
          text: "TOTAL",
          align: "right",
          value: "total",
          sortable: true,
        },
        {
          text: "BALANCE DUE",
          align: "right",
          value: "due",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    total() {
      let total = 0;
      this.Purchase.map((row) => {
        total += row.total;
      });
      return total;
    },
    due() {
      let total = 0;
      this.Purchase.map((row) => {
        total += row.due;
      });
      return total;
    },
  },
  async created() {
    this.start = this.$route.query.start;
    this.end = this.$route.query.end;
    await this.getAll();
  },
  components: { ReportViewBar },
  methods: {
    viewPurchase(purchase) {
      const routeData = this.$router.resolve({
        path: `/global/purchase/${purchase.id}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    getAll() {
      let where = {
        start: this.start,
        end: this.end,
      };

      return PurchaseService.getAll(where).then((response) => {
        console.log("response PurchaseService", response);
        this.$Progress.finish();
        this.Purchase = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
